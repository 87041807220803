import StickyNav from 'src/components/StickyNav'
import Footer from 'src/components/Footer'

const StickyHeaderLayout = ({ children }) => {
  return (
    <div>
      <StickyNav />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default StickyHeaderLayout
