import { useMediaQuery } from 'react-responsive'
import logo from './neqatsi-logo.webp'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}

let currentYear = new Date().getFullYear();


const Footer = () => {
  return (
    <>
      <Desktop>
        <footer className="container py-5">
          <div className="row">
            <div className="col-4 col-md">
              <div className="row">
                <h5 className="text-grey">Services</h5>
              </div>
              <div className="row">
                <ul className="list-unstyled text-small">
                  <li>
                    <a
                      className="underline-effect text-grey hover-white"
                      href="/custom-software"
                    >
                      Custom Software
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="underline-effect text-grey hover-white"
                      href="/scrum"
                    >
                      Scrum Master
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="underline-effect text-grey hover-white"
                      href="/consulting"
                    >
                      Consulting
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-4 col-md">
              <h5>
                <a
                  className="underline-effect text-grey hover-white"
                  href="/contact"
                >
                  Contact
                </a>
              </h5>
              <ul className="list-unstyled text-small">
                <li>
                  <a
                    className="underline-effect text-grey hover-white"
                    href="mailto: hello@neqatsi.com"
                  >
                    hello@neqatsi.com
                  </a>
                  <br />
                </li>
                <li>
                  <address className="text-grey">
                    <br />
                    734 W Polk St
                    <br />
                    Phoenix, AZ 85007
                  </address>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md mt-4 text-center">
              <img className="mb-2" src={logo} width="50" alt="" />
              <small className="d-block mb-3 text-grey">&copy; {currentYear.toString()}</small>
            </div>
          </div>
          <div className="row float-right mt-4">
            {/*
            <a
              className="underline-effect text-grey hover-white pr-2"
              href="/terms"
            >
              Terms of Use
            </a>
            <a
              className="underline-effect text-grey hover-white"
              href="/privacy"
            >
              Privacy Policy
            </a>
            */}
          </div>
        </footer>
      </Desktop>
      <Mobile>
        <footer className="container py-5">
          <div className="row mx-auto px-4">
            <div className="col">
              <div className="row">
                <h5 className="text-grey">Services</h5>
              </div>
              <div className="row">
                <ul className="list-unstyled text-small">
                  <li>
                    <a
                      className="underline-effect text-grey hover-white"
                      href="/custom-software"
                    >
                      Custom Software
                    </a>
                  </li>
                  <li>
                    <a
                      className="underline-effect text-grey hover-white"
                      href="/consulting"
                    >
                      Consulting
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col">
              <h5>
                <a
                  className="underline-effect text-grey hover-white"
                  href="/contact"
                >
                  Contact
                </a>
              </h5>
              <ul className="list-unstyled text-small">
                <li>
                  <a
                    className="underline-effect text-grey hover-white"
                    href="mailto: hello@neqatsi.com"
                  >
                    hello@neqatsi.com
                  </a>
                  <br />
                </li>
                <li>
                  <address className="text-grey">
                    <br />
                    734 W Polk St
                    <br />
                    Phoenix, AZ 85007
                  </address>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-4 mx-auto">
            <img className="mb-2 mx-auto" src={logo} width="40" alt="" />
          </div>
          <div className="row mx-auto">
            <small className="d-block text-grey mx-auto">&copy; {currentYear.toString()}</small>
          </div>
          <div className="row float-right mt-4">
            {/*
            <a
              className="underline-effect text-grey hover-white pr-2"
              href="/terms"
            >
              Terms of Use
            </a>
            <a
              className="underline-effect text-grey hover-white"
              href="/privacy"
            >
              Privacy Policy
            </a>
            */}
          </div>
        </footer>
      </Mobile>
    </>
  )
}

export default Footer
