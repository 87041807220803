import { useMediaQuery } from 'react-responsive'
import logoWeb from './neqatsi-logo-word.webp'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}

const StickyNav = () => {
  return (
    <>
      <Desktop>
        <div
          className="site-header sticky-top"
          style={{ 'margin-left': '20%', 'margin-right': '20%' }}
        >
          <nav className="navbar navbar-dark navbar-expand-lg d-flex flex-md-row justify-content-between p-3">
            <a
              className="unshadowed-z navbar-brand pt-0"
              href="/"
              aria-label="Product"
            >
              <img
                srcSet={logoWeb}
                type="image/webp"
                alt=""
                style={{ height: '40px' }}
              />
            </a>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav nav-fill ml-auto">
                <div className="nav-item dropdown dropdown-hover mx-5">
                  <a className="nav-link" data-toggle="dropdown" href="/">
                    <span className="underline-effect">Services</span>
                  </a>
                  <div
                    className="dropdown-menu dropdown-hover-menu dropdown-menu-right p-4"
                    style={{ backgroundColor: '#222222' }}
                  >
                    <a
                      className="dropdown-item mt-2 mb-2 pl-3 pr-3"
                      href="/custom-software"
                    >
                      <span className="underline-effect text-grey hover-white">
                        Custom Software
                      </span>
                    </a>
                    {/* <a
                      className="dropdown-item mt-2 mb-2 pl-3 pr-3"
                      href="/scrum"
                    >
                      <span className="underline-effect text-grey hover-white">
                        Scrum Master
                      </span>
                    </a> */}
                    <a
                      className="dropdown-item mt-2 mb-2 pl-3 pr-3"
                      href="/consulting"
                    >
                      <span className="underline-effect text-grey hover-white">
                        Software Consulting
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <a
              className="shadowed-z nav-item nav-link btn btn-primary ml-auto"
              href="/contact"
            >
              <span style={{ fontWeight: 'bold', color: '#fff' }}>
                CONTACT US
              </span>
            </a>
            <button
              style={{ outline: 'none', border: 'none' }}
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="navbar-toggler-icon"></i>
            </button>
          </nav>
        </div>
      </Desktop>
      <Mobile>
        <div className="site-header sticky-top">
          <nav className="navbar navbar-dark navbar-expand-lg d-flex flex-md-row justify-content-between p-3">
            <a
              className="shadowed-z navbar-brand pt-0"
              href="/"
              aria-label="Product"
            >
              <img src={logoWeb} alt="" style={{ height: '40px' }} />
            </a>
            <a
              className="nav-item shadowed-z nav-link btn btn-primary ml-auto p-1"
              href="/contact"
            >
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#fff',
                  fontSize: '0.75em',
                }}
              >
                CONTACT US
              </span>
            </a>
            <button
              style={{ outline: 'none', border: 'none' }}
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="navbar-toggler-icon"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav nav-fill ml-auto mt-2">
                <div className="px-4" style={{ background: '#222222' }}>
                  <h4 className="text-grey">Services</h4>
                  <a className="dropdown-item pt-4" href="/custom-software">
                    <span className="underline-effect text-grey hover-white m-0">
                      Custom Software
                    </span>
                  </a>
                  {/* <a className="dropdown-item" href="/scrum">
                    <span className="underline-effect text-grey hover-white">
                      Scrum Master
                    </span>
                  </a> */}
                  <a className="dropdown-item pt-4 pb-4" href="/consulting">
                    <span className="underline-effect text-grey hover-white">
                      Software Consulting
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </Mobile>
    </>
  )
}

export default StickyNav
