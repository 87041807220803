// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Set } from '@redwoodjs/router'
import StickyHeaderLayout from 'src/layouts/StickyHeaderLayout'

const Routes = () => {
  return (
    <Router>
      <Set wrap={StickyHeaderLayout}>
        <Route path="/consulting" page={ConsultingPage} name="consulting" />
        <Route path="/scrum" page={ScrumPage} name="scrum" />
        <Route path="/custom-software" page={CustomSoftwarePage} name="customSoftware" />
        <Route path="/thanks" page={ThanksPage} name="thanks" />
        <Route path="/contact" page={ContactPage} name="contact" />
        <Route path="/" page={HomePage} name="home" />
      </Set>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
